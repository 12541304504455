import { FormikTouched } from "formik"

import {
  pushToDataLayerInvalidField,
  pushToDataLayerRequiredValidField,
} from "@ecom/ui/utils/pushToDataLayerUtils"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import formatToRequest from "@ecom/ui/helpers/formatToRequest"

import { events } from "./events"

export const getDadata = async (
  type: string,
  data: string,
  options = {},
  url = "https://api-app.sovcombank.ru/v1/cache/dadata"
) => {
  const query = {
    query: data,
    ...options,
  }

  try {
    const res = await fetch(`${url}/${type}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(query),
    })

    if (!res.ok) {
      throw new Error(`Could not fetch, status: ${res.status}`)
    }

    pushToDataLayer(events.dadata.success)

    return await res.json()
  } catch (err) {
    pushToDataLayer(events.dadata.error)

    return console.error("Dadata error", err)
  }
}

export const checkDataLayerField = (
  touched: FormikTouched<{}> | undefined,
  error: string | undefined,
  name: string,
  isChanged?: boolean
) => {
  if (touched) {
    if (!error && isChanged) {
      pushToDataLayerRequiredValidField(name)
    } else if (error) {
      pushToDataLayerInvalidField(name, error)
    }
  }
}

export const formatBirthDate = (value: string | undefined) =>
  value ? formatToRequest({ birthDate: value }, { birthDate: "date" }).birthDate : undefined

// TODO: Использовать regexp только с +7
export const phoneRegExp =
  /^((\+?7|8)[ -] ?)?((\([3|4|5|6|9][0-9][0-9]\)))?([ -])(\d{3}[- ]?\d{2}[- ]?\d{2})$/

export const birthRegExp = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/

export type ProductName = "КК Халва" | "КК Халва Рефинансирование"

export const isNumberOrSpecChars = (value: string) => {
  const pattern = /^[a-zA-Zа-яА-ЯёЁ]+-?[a-zA-Zа-яА-ЯёЁ]*$/i

  return value && value.match(pattern) === null
}

export const isNumberOrSpecCharsWithoutSpace = (value: string) => {
  const pattern =
    /^[a-zA-Zа-яА-ЯёЁ]+(?:\s*-\s*[a-zA-Zа-яА-ЯёЁ]+)?(?:\s+[a-zA-Zа-яА-ЯёЁ]+(?:\s*-\s*[a-zA-Zа-яА-ЯёЁ]+)?)?$/i

  return value && value.match(pattern) === null
}

export const isItMoreThanTwoWords = (value: string) => {
  if (value) {
    const valueArray = value.trimEnd().split(" ")
    return valueArray.length > 2
  }
  return false
}

export const isMaxOfOneHyphen = (value: string) => {
  const pattern = /^(?:[^-]+(?:-[^-]+)?|[^-]+)$/

  return value && value.match(pattern) === null
}

export const isCyrillic = (value: string) => {
  const pattern = /^[^a-zA-Z]+$/

  return value?.length ? pattern.test(value) : true
}
